@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    @font-face {
        font-family: 'VT323';
        src: url('./fonts/VT323.ttf');
    }

    @font-face {
        font-family: 'PressStart2P';
        src: url('./fonts/PressStart2P.ttf');
    }

    html {
        @apply bg-background;
    }
}
